import React from 'react';
import { useQuery } from '@tanstack/react-query';
import apps from '../api/apps';

const defaultOptions = {
  staleTime: 60000,
};

export const useAppsQuery = options => {
  const opts = React.useMemo(() => ({ ...defaultOptions, ...options }), [options]);

  return useQuery({
    queryKey: ['apps'],
    queryFn: apps.listApps,
    ...opts,
  });
};
