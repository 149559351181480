import { css } from 'styled-components';

export default css`
  &.user-editor {
    flex: 1;
    padding-top: 30px;
    .actions {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
  }
`;
