import { Form } from 'connex-cds';
import { isEmpty, isObject, set } from 'lodash';
import { AppPermissions } from './app-permissions/AppPermissions';

const { FIELD_TYPES } = Form;

export default {
  path: 'roles',
  labelStringId: 'roles',
  testId: 'roles',
  keyPath: 'crn',
  fields: [
    { path: 'name', labelStringId: 'name', testId: 'name', dataType: FIELD_TYPES.string },
    {
      path: 'permissions',
      name: 'permissions',
      labelStringId: 'permissions',
      dataType: Form.FIELD_TYPES.string,
      testId: 'permissions',
      hidden: true,
      Component: AppPermissions,
      formatter: () => '',
      transformIn: permissions => {
        if (isEmpty(permissions)) return {};
        return Object.keys(permissions).reduce((newPermObject, appRef) => {
          newPermObject[appRef] = permissions[appRef]?.reduce?.((acc, permission) => {
            acc[permission] = true;
            return acc;
          }, {});
          return newPermObject;
        }, {});
      },
      transformOut: (appRef, permissions) => {
        // Turn permissions object into string array
        return Object.keys(permissions)?.reduce((acc, appKey) => {
          const permissionKeys = Object.keys(permissions[appKey]);
          acc[appKey] = permissionKeys || [];
          return acc;
        }, {});
      },
    },
  ],
};
