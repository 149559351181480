import { http } from 'connex-cds';
import { getLocationInfo } from '../../util/getLocationInfo';

export default {
  listUsers: ({ entityRef }) => http.get({ apiName: 'company-admin', path: `/${entityRef}/users` }),
  inviteUser: ({ entityRef, user }) =>
    http.post({
      apiName: 'company-admin',
      path: `/${entityRef}/users/invite`,
      data: { ...user, locationInfo: getLocationInfo() },
    }),
  updateUser: ({ entityRef, profile }) =>
    http.patch({ apiName: 'company-admin', path: `/${entityRef}/users/${profile.profileRef}`, data: profile }),
  register: ({ entityRef, payload }) =>
    http.post({ apiName: 'company-admin', path: `/users/register/${entityRef}`, data: payload }),
};
