import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';
import { roles } from '../api';

export const useListRoles = () => {
  const { entityRef } = useParams();

  const query = useQuery({
    queryKey: ['roles', entityRef],
    queryFn: () => roles.listRoles({ entityRef }),
    staleTime: Infinity,
    enabled: !!entityRef,
  });

  return query;
};

export const useCreateRole = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['roles', entityRef];

  const mutation = useMutation({
    mutationFn: role => roles.createRole({ entityRef, role }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};

export const useUpdateRole = () => {
  const { entityRef } = useParams();
  const queryClient = useQueryClient();

  const queryKey = ['roles', entityRef];

  const mutation = useMutation({
    mutationFn: role => roles.updateRole({ entityRef, role }),
    onMutate: async newItem => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      const newData = previousItem?.map?.(item => (item.crn === newItem.crn ? newItem : item));
      queryClient.setQueryData(queryKey, newData);
      return { previousItem, newItem };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation.mutateAsync;
};
