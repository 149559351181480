import cn from 'classnames';
import { Drawer, Form } from 'connex-cds';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useListRoles } from '../../query-hooks/roles';
import style from './style';

const { DETAIL_TYPES } = Form;

const Styled = styled.div`
  ${style}
`;

export const UserEditor = ({ updateQuery, createQuery, context }) => {
  const { values, mode, Components } = Form.useFormContext();
  const { closeDrawer } = Drawer.useDrawerContext();
  const { detailType } = context;
  const navigate = useNavigate();

  const onFinally = React.useCallback(() => {
    const behaviors = {
      [DETAIL_TYPES.VIEW]: () => navigate(`..`), // TODO: Switch to edit mode and stay put.
      [DETAIL_TYPES.DRAWER]: closeDrawer,
      [DETAIL_TYPES.FULL_WIDTH_DRAWER]: closeDrawer,
      [DETAIL_TYPES.MODAL]: () => {},
    };
    behaviors[detailType]?.();
  }, [closeDrawer, detailType, navigate]);

  const handleCreate = React.useCallback(
    role =>
      createQuery?.(role)?.then(response => {
        onFinally?.();
        return response;
      }),
    [createQuery, onFinally]
  );

  const handleUpdate = React.useCallback(
    role => {
      return updateQuery?.(role)?.then(response => {
        onFinally?.();
        return response;
      });
    },
    [onFinally, updateQuery]
  );

  const roleQuery = useListRoles();

  return (
    <Styled className={cn('user-editor')}>
      <Components.FirstName />
      <Components.LastName />
      <Components.Email />
      <Components.PhoneNumber />
      <Components.Role options={roleQuery.data} busy={roleQuery.isLoading} />

      <div className="actions">
        <Components.CancelButton />
        <Components.SaveButton onUpdate={handleUpdate} />
      </div>
    </Styled>
  );
};
